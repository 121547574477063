.marquee-container {
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

.marquee {
  display: inline-block;
  font-size: 180px;
  animation: marquee 10s linear infinite;

  .marquee-content {
    display: inline-block;
    white-space: nowrap;
  }

  span {
    color: $textPrimary;
    font-family: $primaryFont;
    font-weight: 800;
  }

  .blue-hyphen {
    color: $textSecondary;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start from the right end of the screen */
  }
  100% {
    transform: translateX(-50%); /* Stay off the left end of the screen */
  }
}
