@mixin custom-h1 {
  font-size: 6rem;
  text-transform: uppercase;
  color: $textPrimary;
  font-family: $primaryFont;
  font-weight: 600;
  letter-spacing: 10px;
}

@mixin custom-h2($color: $textPrimary) {
  font-size: 14px;
  text-transform: uppercase;
  color: $color;
  font-family: $primaryFont;
  font-weight: 500;
}

@mixin custom-h3($color: $textPrimary) {
  font-size: 45px;
  text-transform: capitalize;
  color: $color;
  font-family: $primaryFont;
  font-weight: 800;
}

@mixin custom-text($color: $textPrimary) {
  font-size: 18px;
  color: $color;
  font-family: $primaryFont;
  font-weight: 400;
}

@mixin card-title {
  font-size: 18px;
  font-family: $primaryFont;
  font-weight: 800;
  line-height: 22px;
  color: $textPrimary;
}

@mixin card-details {
  font-size: 16px;
  font-family: $primaryFont;
  font-weight: 400;
  line-height: 20px;
  color: $textLightContrast;
}
