.contact-section {
  width: 100%;
  // height: 100vh;
  background-color: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10%;

    .contact-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .contact-title {
        @include custom-h2($textLightContrast);
        margin-bottom: 3rem;
      }

      .subtitle-container {
        // width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        margin-bottom: 3rem;
        text-align: center;

        .contact-subtitle {
          @include custom-h3($textPrimary);
          text-transform: none;
          font-weight: 600;
          font-size: 25px;

          &.colored {
            color: $textSecondary;
          }
        }
      }
    }

    .contact-info {
      width: 100%;
      display: flex;
      align-items: center;
      align-content: stretch;
      justify-content: space-between;
      gap: 5rem;
      margin: 5rem 0;

      .contact-info-container {
        flex: 2;
        padding: 2rem;
        background-color: $textSecondary;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 19rem;

        .contact-title {
          @include custom-h3($textPrimary);
          text-transform: none;
          font-weight: 600;
          font-size: 25px;
          margin-bottom: 2rem;
        }

        .row {
          display: flex;
          align-items: center;
          gap: 1.5rem;

          img {
            width: 30px;
          }

          .contact-information {
            @include custom-h3($textPrimary);
            text-transform: none;
            font-size: 18px;
            font-weight: 300;
          }
        }

        .link-row {
          display: none;
        }
      }

      .contact-form-container {
        flex: 3;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .contact-section {
    // height: auto;
    .contact-container {
      .contact-text {
        align-items: flex-start;
        .subtitle-container {
          align-items: flex-start;
          text-align: start;
          justify-content: flex-start;
          gap: 0;
          line-height: 38px;
          .contact-subtitle {
            font-size: 26px;
            font-weight: 500;
          }
        }
      }

      .contact-info {
        flex-direction: column;
        margin: 0 5%;
        .contact-info-container {
          height: 500px;
          width: 80%;
          padding: 2rem;
          gap: 2rem;

          .link-row {
            display: flex;
            justify-content: space-between;
            padding-top: inherit;

            img {
              color: $textPrimary;
              width: 40px;
            }
          }

          .row {
            img {
              width: 20px;
            }
            .contact-information {
              font-size: 16px;
            }
          }
        }
        .contact-form-container {
          width: 100%;
        }
      }
    }
  }
}
