.about-section {
  width: 100%;
  height: 100vh;
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;

  .about-container {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    margin: 0 10%;
    gap: 6rem;

    .about-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      line-height: normal;

      .about-title {
        @include custom-h2($textLightContrast);
        margin-bottom: 1rem;
      }

      .about-subtitle {
        @include custom-h3($textPrimary);

        &.colored {
          color: $textSecondary;
        }
      }

      .about-text {
        @include custom-text($textLightContrast);
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        text-align: start;

        &.colored {
          color: $textThernary;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .about-section .about-container {
    align-items: center;
    gap: 4rem;

    img {
      width: 45%;
    }
    .about-text {
      .about-title,
      .about-subtitle {
        font-size: 32px;
      }

      .about-text {
        padding-top: 10px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .about-section {
    height: auto;
    .about-container {
      img {
        display: none;
      }

      .about-text {
        width: 100%;

        .about-subtitle {
          font-size: 40px;

          &.colored {
            color: $textSecondary;
          }
        }

        .about-text {
          // font-size: 35px;
          margin-top: 3rem;
          line-height: 22px;

          &.colored {
            color: $textThernary;
          }
        }
      }
    }
  }
}
