.home-section {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 12%,
      rgba(25, 25, 25, 0.82) 32%,
      rgba(0, 0, 0, 1) 44%
    ),
    url("../../assets/images/vortex-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .home-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 10%;

    .home-title {
      @include custom-h1;
      // font-size: 7rem;
      // text-transform: uppercase;
      // color: $textPrimary;
      // font-family: Plus Jakarta Sans;
      // font-weight: 600;
      // letter-spacing: 10px;
    }

    .home-subtitle {
      font-size: 3rem;
      text-transform: capitalize;
      color: $textThernary;
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-family: Plus Jakarta Sans;
      font-weight: 300;
    }

    .home-text {
      font-size: 18px;
      color: $textPrimary;
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-family: Plus Jakarta Sans;
      font-weight: 200;
      font-style: italic;
      letter-spacing: 1px;
      line-height: 26px;
    }

    .home-button {
      font-family: Plus Jakarta Sans;
      font-weight: 500;
      color: $textPrimary;
      font-size: 1rem;
      background-color: $textSecondary;
      // border-radius: 8px;
      border: none;
      padding: 1rem;
      width: 10rem;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1050px) {
  .home-section .home-container {
    width: 50%;
  }
}

@media screen and (max-width: 550px) {
  .home-section {
    height: 80vh;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.593) 10%,
        rgba(0, 0, 0, 0.426) 100%
      ),
      radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 10%,
        rgba(25, 25, 25, 0.872) 52%,
        rgb(0, 0, 0) 80%
      ),
      url("../../assets/images/vortex-bg.png");
    background-position: center;
    background-size: cover;

    .home-container {
      display: flex;
      flex-direction: column;
      width: 100vw;
      margin: 0;
      padding: 0;

      .home-title {
        font-size: 4rem;
        text-transform: uppercase;
        color: $textPrimary;
        font-family: Plus Jakarta Sans;
        font-weight: 600;
        letter-spacing: 10px;
        padding: 0 10%;
      }

      .home-subtitle {
        padding: 0 10%;
        font-size: 2.2rem;
        text-transform: capitalize;
        color: $textThernary;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-family: Plus Jakarta Sans;
        font-weight: 300;
      }

      .home-text {
        padding: 0 10%;
        font-size: 18px;
        margin-top: 1rem;
        letter-spacing: 0;
        font-weight: 500;
      }

      .home-button {
        margin: 4% 10%;
        width: 50%;
      }
    }
  }
}
