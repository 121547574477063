.service-section {
  width: 100%;
  background-color: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10%;
    padding-top: 6rem;

    .service-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .service-title {
        @include custom-h2($textLightContrast);
        margin-bottom: 3rem;
      }

      .subtitle-container {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        margin-bottom: 3rem;

        .service-subtitle {
          @include custom-h3($textPrimary);
          &.colored {
            color: $textSecondary;
          }
        }
      }
    }

    .service-cards {
      display: flex;
      gap: 2rem;
      overflow-x: auto; /* Enable horizontal scrolling */
      scroll-snap-type: x mandatory; /* Ensure the cards snap into place while scrolling */
      width: 100%; /* Full width of the screen */
      padding: 1rem 0; /* Optional padding for spacing */
    }

    .service-quotation {
      display: flex;
      gap: 5rem;
      margin: 10rem 0;

      img {
        width: auto;
        height: 8rem;
      }

      .quotation-text-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .quotation-text {
          @include card-details;
          font-size: 30px;
          line-height: 50px;
        }
        .line {
          margin-top: 2rem;
          background-color: #95e7ff;
          width: 100%;
          height: 2px;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .service-section
    .service-container
    .service-quotation
    .quotation-text-container
    .quotation-text {
    font-size: 26px;
    line-height: 40px;
  }
}

@media screen and (max-width: 550px) {
  .service-section .service-container {
    .service-cards {
      gap: 2rem;
      padding: 2rem 0;
    }

    .service-text .subtitle-container .service-subtitle {
      font-size: 42px;
    }
    .service-quotation {
      flex-direction: column;
      align-items: center;

      img {
        width: 8rem;
        height: 8rem;
      }

      .quotation-text-container {
        .quotation-text {
          font-size: 22px;
          line-height: normal;
        }
      }
    }
  }
}
