.form-container {
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 2rem;

    .input {
      height: 30px;
      padding: 8px;
      background-color: $secondaryColor;
      color: $textLightContrast;
      color: $textLightContrast;
      font-size: 14px;
      border: 2px solid #282727;
    }

    .submit-button {
      height: 40px;
      background-color: $textSecondary;
      color: $textPrimary;
      font-size: 14px;
      letter-spacing: 0.5px;
      word-spacing: 4px;
    }

    .textarea {
      height: 110px;
      padding: 8px;
      background-color: $secondaryColor;
      color: $textLightContrast;
      border: 2px solid #282727;
      font-size: 14px;
    }
  }
}
