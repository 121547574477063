.main-screen {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // overflow-y: scroll;
    // overflow-x: hidden;
    background-color: $primaryColor;
}
