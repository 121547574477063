.portfolio-section {
  width: 100%;
  background-color: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 10%;
    gap: 8rem;

    .portfolio-photos {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%; /* Ensure image does not overflow the container */
        height: auto; /* Maintain aspect ratio */
      }

      .portfolio {
        display: flex;

        &.mob {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .portfolio-section {
    .portfolio-container {
      margin-bottom: 3rem;

      .portfolio-photos {
        img {
          max-width: 100%; /* Ensure image does not overflow the viewport */
          height: auto; /* Maintain aspect ratio */
        }

        .portfolio {
          display: none;
          &.mob {
            display: flex;
            max-width: 100vw;
            height: auto;
          }
        }
      }
    }
  }
}
